import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Newsletter from '../components/newsletter'
import { Modal } from 'react-overlays'
import Recaptcha from 'react-recaptcha'
import {FormattedMessage} from 'react-intl'
import {filterByLang} from '../utility/utils'


var callback = function () {
  console.log('Done!!!!');
};

// specifying verify callback function
var verifyCallback = function (response) {
  console.log(response);
};

const Contatti = ({ pageContext: { locale } })  => {
  const { topimg, allContentfulContenutoGenerico } = useStaticQuery(
    graphql`
      query {
        topimg: file(relativePath: { eq: "testate/header_contatti.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1240) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        allContentfulContenutoGenerico(filter: {contentful_id: {eq: "2D1jPvKUkji9OHOtFrOXHa"}}) {
          edges {
            node {
              node_locale
              claim {
                childMarkdownRemark {
                  html
                }
              }
              testo {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }  
      }
    `
  ); 

  const intro = filterByLang(allContentfulContenutoGenerico,locale)[0];

  const [name, setName] = useState(""); 
  const [email, setEmail] = useState(""); 
  const [messaggio, setMessaggio] = useState(""); 
  const [uso, setUso] = useState(""); 
  const [tipo, setTipo] = useState(""); 
  const [showModal, setShowModal] = useState(false); 
  const [success, setSuccess] = useState(false); 


  const close = () => {
    setShowModal(false);
    setSuccess(false);
  }

  const renderBackdrop = (props) => {
    return <div {...props} className="modal-bg" />;
  }

  const handleForm = (e) => {
   
      e.preventDefault();
      var url='https://xzehs0z23i.execute-api.eu-west-1.amazonaws.com/dev/email/send';
      //var tipofield = document.getElementById("tipo");
      //var usofield = document.getElementById("uso");
      var data = {
          name:  name,
          email: email,
          uso: uso,
          tipo: tipo,
          message: messaggio,
          grecaptcharesponse : document.querySelector('textarea[name="g-recaptcha-response"]').value,
      };
      //console.log('About to post: ', data);
      setShowModal(true);
      setSuccess(false);
      return fetch(url, {
          method: "POST", 
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
      })
      .then(response => {
        setSuccess(true);
      }
      ).catch(err => {
        setShowModal(false);
        setSuccess(false);
      });
      
  }
 


    return (
      <Layout locale={locale}>
        <SEO title="contatti" />
        <div className="row">
          <div className="col-12 text-center">
            <FormattedMessage id="contatti">
                {txt => <h1 className="pagetitle">{txt}</h1>}
            </FormattedMessage>
          </div>
        </div>
        <div className="section section--top">
          <div className="row">
            <div className="col-12">
              <Img className="section__topimg" fluid={topimg.childImageSharp.fluid} />
            </div>
          </div>
        </div>

        <div className="section">
          <div className="row">
            <div className="col-12 ">
              {intro.claim &&
                <div className="section__incipit"  dangerouslySetInnerHTML={{__html: intro.claim.childMarkdownRemark.html}} />
              }
              <div className="section__text"  dangerouslySetInnerHTML={{__html: intro.testo.childMarkdownRemark.html}} />
            </div>
          </div>
        </div>
        <div className="section form"> 
          <div className="row">
              <div className="col-md-12 col-lg-10 offset-lg-1">
                <form action="#" className="contatti" onSubmit={handleForm} method="post">
                  <fieldset>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="field__group">
                          <FormattedMessage id="nomeCognome">
                            {txt => <label htmlFor="name">{txt}*</label>}
                          </FormattedMessage>
                          <input type="text" id="name"  required  name="name" onChange={e => setName(e.target.value)}/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="field__group">
                          <FormattedMessage id="tuaEmail">
                            {txt => <label htmlFor="email">{txt}*</label>}
                          </FormattedMessage>
                          <input type="email" id="email" required  name="email" onChange={e => setEmail(e.target.value)}/>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="field__group">
                          <FormattedMessage id="uso">
                            {txt => <label htmlFor="uso">{txt}*</label>}
                          </FormattedMessage>
                          <select  id="uso"  required  name="uso" onChange={e => setUso(e.target.value)}>
                            <FormattedMessage id="commerciale">
                              {txt => <option>{txt}</option>}
                            </FormattedMessage>
                            <FormattedMessage id="privato">
                              {txt => <option>{txt}</option>}
                            </FormattedMessage>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="field__group">
                          <FormattedMessage id="tipologia">
                            {txt => <label htmlFor="tipologia">{txt}*</label>}
                          </FormattedMessage>
                          <select  id="tipo" required  name="tipo" onChange={e => setTipo(e.target.value)}>
                            <FormattedMessage id="packaging">
                              {txt => <option>{txt}</option>}
                            </FormattedMessage>
                            <FormattedMessage id="menu">
                              {txt => <option>{txt}</option>}
                            </FormattedMessage>
                            <FormattedMessage id="accessori">
                              {txt => <option>{txt}</option>}
                            </FormattedMessage>
                            <FormattedMessage id="comunicazione">
                              {txt => <option>{txt}</option>}
                            </FormattedMessage>
                            <FormattedMessage id="ricetteDisegnate">
                              {txt => <option>{txt}</option>}
                            </FormattedMessage>
                            <FormattedMessage id="illustrazionePersonalizzata">
                              {txt => <option>{txt}</option>}
                            </FormattedMessage>
                            <FormattedMessage id="partecipazioni">
                              {txt => <option>{txt}</option>}
                            </FormattedMessage>
                            <FormattedMessage id="corso">
                              {txt => <option>{txt}</option>}
                            </FormattedMessage>
                            <FormattedMessage id="altro">
                              {txt => <option>{txt}</option>}
                            </FormattedMessage>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="field__group">
                          <FormattedMessage id="messaggio">
                            {txt => <label htmlFor="messaggio">{txt}*</label>}
                          </FormattedMessage>
                          <textarea cols="40" rows="5" required id="messaggio"  name="messaggio" onChange={e => setMessaggio(e.target.value)}></textarea>
                        </div>
                      </div>
                    </div>   
                    <div className="row">
                      <div className="col-12 text-center">
                        <Recaptcha
                          sitekey="6LeYNp0UAAAAACTsg34Hr1kWQVKOItWn8ZZrlKSy"
                          render="explicit"
                          verifyCallback={verifyCallback}
                          onloadCallback={callback}
                        />
                      </div>
                    </div>                    
                  </fieldset>
                  <div className="row">
                      <div className="col-md-12 text-center">
                        <FormattedMessage id="invia">
                              {txt =>  <input type="submit" className="btn-action btn-action--white" value={txt}/>}
                        </FormattedMessage> 
                      </div>
                  </div>  
              </form>
              </div>
          </div>
          
            
        </div>

        <Newsletter/>

        <Modal
          onHide={() => close()}
          aria-labelledby="modal-label"
          show={showModal}
          className="modal-box"
          renderBackdrop={renderBackdrop}
        >
          {success ?
            <div>
              <FormattedMessage id="grazie">
                {txt => <h2>{txt}</h2>}
              </FormattedMessage>
              <p>
                <FormattedMessage id="grazieContatto"/>
                <br/>
                <FormattedMessage id="rispondero"/>
              </p>
            </div> :
            <div className="lds-roller">
              <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div>
          }
        </Modal>

      </Layout>
    )
};

export default Contatti


